import { createAsyncThunk } from '@reduxjs/toolkit';
import { pmProjectsService } from '../../../services/projectManagement/pmProjects.services';
import { showHideLoader } from '../../common/common.slice';


// Get projects list action
// change-req 
const getProjectsListAction = createAsyncThunk('pmProjectsSlice/getProjectsListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    // dispatch(showHideLoader(true));

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.getProjectListService({
        ...payload,
        id: projectDetails?.projectManager?.id?? payload?.id
    })
        .then((response) => {
            // dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            // dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project center invoices list action
// change-req 
const getProjectInvoicesListAction = createAsyncThunk('pmProjectsSlice/getProjectInvoicesListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.getProjectInvoicesListService({
        ...payload,
        pm_id: projectDetails?.projectManager?.id?? payload?.pm_id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project center invoice details action
// change-req 
const getProjectInvoiceDetailsAction = createAsyncThunk('pmProjectsSlice/getProjectInvoiceDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.getProjectInvoiceDetailsService({
        ...payload,
        pm_id: projectDetails?.projectManager?.id?? payload?.pm_id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Release project center invoice action
// change-req 
const releaseInvoicesAction = createAsyncThunk('pmProjectsSlice/releaseInvoicesAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    
    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.releaseInvoicesService({
        ...payload,
        pm_id: projectDetails?.projectManager?.id?? payload?.pm_id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Upload project center invoice action
const uploadPdfForInvoiceAction = createAsyncThunk('pmProjectsSlice/uploadPdfForInvoiceAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return pmProjectsService.uploadPdfForInvoiceService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// Upload project center change order action
const uploadPdfForChangeOrderAction = createAsyncThunk('pmProjectsSlice/uploadPdfForChangeOrderAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return pmProjectsService.uploadPdfForChangeOrderService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
});

// Get project overview details action
// change-req 
const getProjectOverviewDetailsAction = createAsyncThunk('pmProjectsSlice/getProjectOverviewDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.projectOverviewDetailsService({
        ...payload,
        id: projectDetails?.projectManager?.id?? payload?.id
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project timeline task-list action
// change-req 
const getProjectTimelineListAction = createAsyncThunk('pmProjectsSlice/getProjectTimelineListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.getProjectTimelineListService({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Update project task connections action
// change-req
const updateTaskConnectionAction = createAsyncThunk('pmProjectsSlice/updateTaskConnectionAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }
    
    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.updateTaskConnectionsService({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project phase tasks list action
// change-req
const getProjectTasksListAction = createAsyncThunk('pmProjectsSlice/getProjectTasksListAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.getProjectTasksService({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        // response is being altered in the slice
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project overview task details action
// change-req
const getProjectOverviewTaskDetailsAction = createAsyncThunk('pmProjectsSlice/getProjectOverviewTaskDetailsAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.projectOverviewTaskDetailsService({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Get project phases action
// change-req
const getProjectPhasesAction = createAsyncThunk('pmProjectsSlice/getProjectPhasesAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.getProjectPhasesList({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Add project task action
// change-req
const addProjectTaskAction = createAsyncThunk('pmProjectsSlice/addProjectTaskAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.addProjectTaskService({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Update project task action
// change-req 
const updateProjectTaskAction = createAsyncThunk('pmProjectsSlice/updateProjectTaskAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    if(!payload?.hideLoader){
        dispatch(showHideLoader(true));
    }

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.updateProjectTaskService({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// Delete project task action
// change-req
const deleteProjectTaskAction = createAsyncThunk('pmProjectsSlice/deleteeProjectTaskAction', async(payload: any, {getState, dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));

    const { salesProjects: { projectDetails } } = await getState() as any;

    return pmProjectsService.deleteProjectTaskService({
        ...payload,
        pmId: projectDetails?.projectManager?.id?? payload?.pmId
    })
        .then((response) => {
            dispatch(showHideLoader(false));
            return response;
        })
        .catch((error: {response: unknown;}) => {
            dispatch(showHideLoader(false));
            return rejectWithValue(error?.response);
        });
});

// get change order Action
const getChangeOrderAction = createAsyncThunk(
    'pmProjectsSlice/getChangeOrderAction', (payload: any, { dispatch, rejectWithValue }) => {
      dispatch(showHideLoader(true));
      return pmProjectsService.getChangeOrderService(payload)
        .then((response) => {
          setTimeout(() => {
            dispatch(showHideLoader(false))
          }, 500)
          return response
        })
        .catch((error: { response: unknown; }) => {
          setTimeout(() => {
            dispatch(showHideLoader(false))
          }, 500)
          return rejectWithValue(error?.response)
        })
    }
  );

// get change order items Action
const getChangeOrderItemsAction = createAsyncThunk(
    'pmProjectsSlice/getChangeOrderItemsAction', (payload: any, { dispatch, rejectWithValue }) => {
        //   dispatch(showHideLoader(true));
        return pmProjectsService.getChangeOrderItemsService(payload)
            .then((response) => {
                //   setTimeout(() => {
                //     dispatch(showHideLoader(false))
                //   }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                //   setTimeout(() => {
                //     dispatch(showHideLoader(false))
                //   }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// create change order Action
const createChangeOrderAction = createAsyncThunk(
    'pmProjectsSlice/createChangeOrderAction', (dealId: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.createChangeOrderService(dealId)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// create change order Action
const saveChangeOrderAction = createAsyncThunk(
    'pmProjectsSlice/saveChangeOrderAction', ({change_order_id, payload}:any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.saveChangeOrderService(change_order_id, payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// create change order Action
const getChangeOrderDetailsAction = createAsyncThunk(
    'pmProjectsSlice/getChangeOrderDetailsAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.getChangeOrderDetailsService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// get change order terms Action
const getChangeOrderTermsAction = createAsyncThunk(
    'pmProjectsSlice/getChangeOrderTermsAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.getChangeOrderTermsService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);


// save change order notes Action
const saveChangeOrderNotesAction = createAsyncThunk(
    'pmProjectsSlice/saveChangeOrderNotesAction', ({change_order_id,payload}: any, { dispatch, rejectWithValue }) => {
        // dispatch(showHideLoader(true));
        return pmProjectsService.saveChangeOrderNotesService(change_order_id,payload)
            .then((response) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// save change order Intro Action
const saveChangeOrderIntroAction = createAsyncThunk(
    'pmProjectsSlice/saveChangeOrderIntroAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.saveChangeOrderIntroService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const uploadChangeOrderPdfAction = createAsyncThunk(
    'pmProjectsSlice/uploadChangeOrderPdfAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.uploadChangeOrderPdfService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const changeOrderAttachmentListAction = createAsyncThunk(
    'pmProjectsSlice/changeOrderAttachmentListAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.changeOrderAttachmentListService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const removeChangeOrderAttachmentAction = createAsyncThunk(
    'pmProjectsSlice/removeChangeOrderAttachmentAction', (attachmentId: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.removeChangeOrderAttachmentService(attachmentId)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// upload change order pdf Action
const exportChangeOrderAction = createAsyncThunk(
    'pmProjectsSlice/exportChangeOrderAction', ({change_order_id,payload}: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.exportChangeOrderService(change_order_id,payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// delete change order Action
const deleteChangeOrderAction = createAsyncThunk(
    'pmProjectsSlice/deleteChangeOrderAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.deleteChangeOrderService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// duplicate change order Action
const duplicateChangeOrderAction = createAsyncThunk(
    'pmProjectsSlice/duplicateChangeOrderAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.duplicateChangeOrderService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// get co payment schedule Action
const getCOPaymentScheduleAction = createAsyncThunk(
    'pmProjectsSlice/getCOPaymentScheduleAction', (change_order_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.getCOPaymentScheduleService(change_order_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// get co payment schedule Action
const updateCOPaymentScheduleAction = createAsyncThunk(
    'pmProjectsSlice/updateCOPaymentScheduleAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.updateCOPaymentScheduleService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Rfp List Action
const rfpListAction = createAsyncThunk(
    'pmProjectsSlice/rfpListAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.rfpListService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Create Rfp Action
const createRfpAction = createAsyncThunk(
    'pmProjectsSlice/createRfpAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.createRfpService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Update Rfp Action
const updateRfpAction = createAsyncThunk(
    'pmProjectsSlice/updateRfpAction', (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.updateRfpService(payload)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// Unrelease change order Action
const unreleaseChangeOrderAction = createAsyncThunk(
    'pmProjectsSlice/unreleaseChangeOrderAction', (change_orde_id: any, { dispatch, rejectWithValue }) => {
        dispatch(showHideLoader(true));
        return pmProjectsService.unreleaseChangeOrder(change_orde_id)
            .then((response) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// rearrange overview list Action
// change-req 
const rearrangeOverviewListAction = createAsyncThunk(
    'pmProjectsSlice/rearrangeOverviewListAction', async(payload: any, { getState, dispatch, rejectWithValue }) => {
        // dispatch(showHideLoader(true));

        const { salesProjects: { projectDetails } } = await getState() as any;

        return pmProjectsService.rearrangeProjectOverviewDetailsService({
            ...payload,
            userId: projectDetails?.projectManager?.id?? payload?.userId
        })
            .then((response) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);

// generate zip Action
const generateZipAction = createAsyncThunk(
    'pmProjectsSlice/generateZipAction', (payload: any, { dispatch, rejectWithValue }) => {
        // dispatch(showHideLoader(true));
        return pmProjectsService.generateZip(payload)
            .then((response) => {
                setTimeout(() => {
                    // dispatch(showHideLoader(false))
                }, 500)
                return response
            })
            .catch((error: { response: unknown; }) => {
                setTimeout(() => {
                    dispatch(showHideLoader(false))
                }, 500)
                return rejectWithValue(error?.response)
            })
    }
);


// Export all Projects Action
export const pmProjectsAction = {
    getProjectsListAction,
    getProjectInvoicesListAction,
    getProjectInvoiceDetailsAction,
    releaseInvoicesAction,
    uploadPdfForInvoiceAction,
    uploadPdfForChangeOrderAction,
    getProjectOverviewDetailsAction,
    getProjectTimelineListAction,
    updateTaskConnectionAction,
    getProjectTasksListAction,
    getProjectOverviewTaskDetailsAction,
    getProjectPhasesAction,
    addProjectTaskAction,
    updateProjectTaskAction,
    deleteProjectTaskAction,
    getChangeOrderAction,
    getChangeOrderItemsAction,
    createChangeOrderAction,
    saveChangeOrderAction,
    getChangeOrderDetailsAction,
    getChangeOrderTermsAction,
    saveChangeOrderNotesAction,
    saveChangeOrderIntroAction,
    uploadChangeOrderPdfAction,
    changeOrderAttachmentListAction,
    removeChangeOrderAttachmentAction,
    exportChangeOrderAction,
    deleteChangeOrderAction,
    duplicateChangeOrderAction,
    getCOPaymentScheduleAction,
    updateCOPaymentScheduleAction,
    rfpListAction,
    createRfpAction,
    updateRfpAction,
    unreleaseChangeOrderAction,
    rearrangeOverviewListAction,
    generateZipAction
}