import FormControl from '@mui/material/FormControl';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import palette from '../../../theme/palette';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Link } from 'react-router-dom';

const BootstrapFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginTop: '27px',
  'label': {
    transform: "translate(0, -9px) scale(0.75)",
  }
}));
const BootstrapInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 'bold',
  fontFamily: [
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    // 'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  color: palette.primary.darker,
  '& .MuiInputLabel-outlined': {
    transform: 'translate(0, -9px) scale(0.75)'
  }
}));

const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),

  },
  // border: `1px solid ${palette.borderColor}`,
  border: `none`,
  borderRadius: 0,
  '&:focus': {
    boxShadow: `${alpha(theme.palette.secondary.dark, 0.25)} 0 0 0 0.1rem`,
    borderColor: theme.palette.secondary.dark,
  },
  '& type=["password"]': {
    height: '40px',
  },
  '&:hover': {
    boxShadow: `none`,
    // borderColor: theme.palette.secondary.dark,
  },
  '& .MuiInputBase-input': {
    fontSize: 14,
    lineHeight: 24,
    boxSizing: 'border-box',
    padding: '10px 12px',
    height: '40px',
    backgroundColor: palette.grey[0],
    border: "none",
    boxShadow: "none",
    borderColor: palette.primary.darker,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      // 'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  '.error *': {
    // color: `${palette.error.dark} !important`
    color: `red !important`,
    borderColor: `red !important`,
  }
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label.MuiInputLabel-formControl': {
    fontSize: 14,
    lineHeight: 24,
    fontWeight: 'bold',
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      // 'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  'label + &': {
    marginTop: theme.spacing(3),

  },

  '& .MuiInputBase-input': {
    borderRadius: 0,
    position: 'relative',
    // backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    backgroundColor: palette.grey[0],
    border: `1px solid ${palette.borderColor}`,
    fontSize: 14,
    minWidth: '100%',
    boxSizing: 'border-box',
    padding: '10px 12px',
    height: '40px !important',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      // 'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.secondary.dark, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.secondary.dark,
    },
    '& type=["password"]': {
      height: '40px',
    }
  },
}));

export default function CustomInputField(props: any) {
  
  const { name, label, id, onChange, error, autoComplete, fullWidth, autoFocus, placeHolder, type, endAdornment, onBlur, value, disabled, showPassword, showForgotPassword, required } = props;

  return (
    <>
      {type !== 'password' && <BootstrapFormControl variant="outlined" className={error[name].length > 0 ? "error" : ""}>
        {label && (
          <BootstrapInputLabel className="inputLabel" shrink htmlFor={id}>
            {label}{required && "*" }
          </BootstrapInputLabel>
        )}
        <CustomOutlinedInput
          autoComplete={autoComplete}
          required={required}
          name={name}
          fullWidth={fullWidth}
          id={id}
          autoFocus={autoFocus || false}
          placeholder={placeHolder || "Enter"}
          type={type}
          endAdornment={endAdornment}
          onChange={(e) => onChange(e, name)}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
        />
        {error[name].length > 0 && <p className="inputErrorMessage">{error[name]}</p>}
      </BootstrapFormControl>}
      {type === 'password' && <BootstrapFormControl variant="outlined" className={error[name].length > 0 ? "error" : ""}>
        {label && <BootstrapInputLabel className="inputLabel" shrink htmlFor={id}>
          <div className='forgot-password-wrap'>
            {label}
            {showForgotPassword && <Link to="/forgot-password">Forgot password?</Link>}
          </div>
        </BootstrapInputLabel>}
        <CustomOutlinedInput
          autoComplete={autoComplete}
          required={required}
          name={name}
          fullWidth={fullWidth}
          id={id}
          autoFocus={autoFocus || false}
          placeholder={placeHolder || "Enter"}
          type={showPassword ? "text" : type}
          endAdornment={endAdornment}
          onChange={(e) => onChange(e, name)}
          onBlur={onBlur}
          disabled={disabled}
        />
        {error[name].length > 0 && <p className="inputErrorMessage">{error[name]}</p>}
      </BootstrapFormControl>}
    </>
  )
}
