// Auth
export const LOGIN = "/login"; //login
export const FORGOT_PASSWORD = "/forgot-password"; //forgot password

// Users
export const USERS_LISTING = "/users/"; //User Listing
export const CREATE_USER = "/users/create/"; //Create User

// OFFICE
export const OFFICES_LISTING = "/offices"; // Office Listing
export const CREATE_OFFICE = "/offices/create/"; //Create Offices
export const UPDATE_OFFICE = "/offices/:officeId/"; //Update Offices

// Roles
export const CREATE_ROLE = "/roles/create/"; //Create Role
export const EDIT_ROLE = "/role/:roleId"; //Create Role

// Projects
// export const PROJECTS = "/"; // Project2
export const CREATE_PROJECT = "/projects/create"; //Create Project

// Sales
// Sales Projects
export const SALES_PROJECTS = "/sales/projects"; // Sales Project
export const SALES_PROJECT_DETAILS = "/sales/projects/details/:projectId"; // Sales Project

// Sales Proposals
export const SALES_PROPOSALS = "/sales/proposals"; // Proposals
export const SALES_PROPOSAL_DETAILS = "/sales/proposals/details/:proposalId"; // Sales Proposal Details

// Project Management
// Dashboard
export const PM_DASHBOARD = "/pm/dashboard"; // Dashboard
export const PM_PROJECTS = "/pm/projects"; // PM Projects
export const PM_PROJECT_DETAILS = "/pm/projects/details/:projectId"; // PM Projects
export const PM_PROPOSAL_DETAILS = "/pm/proposals/details/:proposalId"; // PM Proposals

// Mobile View
export const PM_PROJECT_DETAILS_MOBILE = "/mobile/pm/projects/details/:projectId"; // PM Projects mobile

// Design Management
export const DESIGN_DASHBOARD = "/design/dashboard"; // Dashboard
export const DESIGN_PROJECTS = "/design/projects"; // PM Projects
export const DESIGN_PROJECT_DETAILS = "/design/projects/details/:projectId"; // PM Projects

// under maintenance
export const UNDER_MAINTENANCE = "/under-maintenance"; 

// privacy
export const PRIVACY_POLACY ="/privacy-policy"  

// 404
export const PAGE_NOT_FOUNT ="/404"