import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoaderComponent from "../components/Loader/Index.loader";
import SnackbarComponent from "../components/Snackbar/Index.snackbar";
import LoginModal from "../components/Modals/LoginModal/Index.loginModal";
import { snackbarObj } from "../redux/common/common.selectors";
import { loginModal } from "../redux/auth/auth.selectors";
import { useAppSelector } from "../redux/store/store";

// Import all paths
import * as Paths from "./Paths";

// ProjectedRoute to validate if the session is valid or user has relevant permission
import ProtectedRoute from "./ProtectedRoutes";

// Import Page Components
const NotFound = lazy(() => import("../pages/Authentication/NotFound/Index.notFound"));
const Layout = lazy(() => import("./Layout"));
const Login = lazy(() => import("../pages/Authentication/Login/Index.login"));
const ForgotPassword = lazy(() => import("../pages/Authentication/ForgotPassword/Index.forgotPassword"));

// Back Office
// Users
const Users = lazy(() => import("../pages/users/Index.users"));
const CreateUser = lazy(() => import("../pages/users/Users/CreateUser/Index.create"));
const CreateRole = lazy(() => import("../pages/users/Roles/CreateRole/Index.create"));
const EditRole = lazy(() => import("../pages/users/Roles/EditRole/Index.editRole"));
// Projects
const ProjectsListing = lazy(() => import("../pages/projects/projectsListing/Index.projectsListing"));
const CreateProject = lazy(() => import("../pages/projects/createProject/Index.createProject"));
// Offices
const OfficesListing = lazy(() => import("../pages/Offices/Index.offices"));
const CreateOffice = lazy(() => import("../pages/Offices/Offices/CreateOffice/Index.createOffice"));
const UpdateOffice = lazy(() => import("../pages/Offices/Offices/UpdateOffice/Index.updateOffice"));

// Sales
// Projects
const SalesProjects = lazy(() => import('../pages/sales/projects/projectsListing/Index.projectListing'));
const SalesProjectDetails: any = lazy(() => import('../pages/sales/projects/projectDetails/Index.projectDetails'));
// Proposals
const ProposalsListing = lazy(() => import('../pages/sales/proposals/proposalsListing/Index.proposalsListing'));
const SalesProposalDetails = lazy(() => import('../pages/sales/proposals/proposalDetails/Index.proposalDetails'))

// Project Management
const PMDashboard = lazy(() => import ('../pages/projectManagement/dashboard/Index.dashboard'));
const PMProjectDetails:any = lazy(() => import('../pages/projectManagement/projects/projectDetails/Index.projectDetails'));

const PMProposalDetails = lazy(() => import('../pages/projectManagement/proposals/proposalDetails/Index.proposalDetails'));


// Design Management
const DesignDashboard = lazy(() => import('../pages/designManagement/dashboard/Index.dashboard'));
const DesignProjectDetails: any = lazy(() => import('../pages/sales/projects/projectDetails/Index.projectDetails'));

// Redirection path
const authenticationPath = "/unauthorized";

function RoutesWapper() {
  const isAuthenticated = !!localStorage.getItem("token");

  const snackObj = useAppSelector(snackbarObj);
  const loginMod = useAppSelector(loginModal);

  return (
    <>
      {/* snackbar component */}
      <SnackbarComponent snackObj={snackObj} />
      {/* snackbar component */}

      <BrowserRouter>
        <Suspense fallback={<></>}>
          
          {/* login modal */}
          <LoginModal
            modalObj={loginMod}
          />
          {/* login modal */}

          <Routes>
            {/* following routes if you need to render inside the layout (Dashboard,Documents) */}
            <Route element={
              <Layout
                documentType={""}
                view={""}
                handleView={""}
              />
            }>
              {/* Projects */}
              <Route
                index
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    module_name="projects"
                    permissions={["view"]}
                    outlet={<ProjectsListing />}
                  />
                }
              />
              <Route
                path={Paths.CREATE_PROJECT}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    module_name="projects"
                    permissions={["create", "edit"]}
                    outlet={<CreateProject />}
                  />
                }
              />

              {/* Sales */}
              <Route
                path={Paths.SALES_PROJECTS}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="sales" permissions={["view"]} outlet={<SalesProjects />} />}
              />
              <Route
                path={Paths.SALES_PROJECT_DETAILS}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="sales" permissions={["create", "edit"]} outlet={<SalesProjectDetails parent={"sales"} />} />}
              />
              <Route
                path={Paths.SALES_PROPOSALS}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="sales" permissions={["view"]} outlet={<ProposalsListing />} />}
              />
              <Route
                path={Paths.SALES_PROPOSAL_DETAILS}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="sales" permissions={["create", "edit"]} outlet={<SalesProposalDetails />} />}
              />

              {/* Project Management */}

              <Route
                path={Paths.PM_DASHBOARD}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="pm" permissions={["view"]} outlet={<PMDashboard />} />}
              />
              <Route
                path={Paths.PM_PROJECT_DETAILS}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="pm" permissions={["view"]} outlet={<PMProjectDetails parent={"pm"} />} />}
              />
              <Route
                path={Paths.PM_PROPOSAL_DETAILS}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="pm" permissions={["view"]} outlet={<PMProposalDetails />} />}
              />

              {/* Design Management */}
              <Route
                path={Paths.DESIGN_DASHBOARD}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="pm" permissions={["view"]} outlet={<DesignDashboard />} />}
              />
              <Route
                path={Paths.DESIGN_PROJECT_DETAILS}
                element={<ProtectedRoute authenticationPath={authenticationPath} isAuthenticated={isAuthenticated} module_name="pm" permissions={["view"]} outlet={<DesignProjectDetails parent={"pm"} />} />}
              />

              {/* Users */}
              <Route
                path={Paths.USERS_LISTING}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    module_name="users"
                    permissions={["create", "edit", "delete", "view"]}
                    outlet={<Users />}
                  />
                }
              />
              <Route
                path={Paths.CREATE_USER}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    module_name="users"
                    permissions={["create", "edit"]}
                    outlet={<CreateUser />}
                  />
                }
              />
              <Route
                path={Paths.CREATE_ROLE}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    module_name="users"
                    permissions={["create", "edit"]}
                    outlet={<CreateRole />}
                  />
                }
              />
              <Route
                path={Paths.EDIT_ROLE}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    module_name="users"
                    permissions={["create", "edit"]}
                    outlet={<EditRole />}
                  />
                }
              />

              {/* Offices */}
              <Route
                path={Paths.OFFICES_LISTING}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    // "offices" needs to be added into the user-permissions object from backend.
                    // temporarily usng "users" to gain access for development
                    module_name="users"
                    permissions={["create", "edit", "delete", "view"]}
                    outlet={<OfficesListing />}
                  />
                }
              />
              <Route
                path={Paths.CREATE_OFFICE}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    // "offices" needs to be added into the user-permissions object from backend.
                    // temporarily usng "users" to gain access for development
                    module_name="users"
                    permissions={["create", "edit"]}
                    outlet={<CreateOffice />}
                  />
                }
              />
              <Route
                path={Paths.UPDATE_OFFICE}
                element={
                  <ProtectedRoute
                    authenticationPath={authenticationPath}
                    isAuthenticated={isAuthenticated}
                    // "offices" needs to be added into the user-permissions object from backend.
                    // temporarily usng "users" to gain access for development
                    module_name="users"
                    permissions={["create", "edit"]}
                    outlet={<UpdateOffice />}
                  />
                }
              />
            </Route>
            {/* following routes do not need any layout (header,footer) */}
            <Route path={Paths.LOGIN} element={<Login />} />
            <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default RoutesWapper;
